import { SvgIcon } from "@mui/material";

export default function DuplicateIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M8.39844 10.8C8.39844 10.1635 8.65129 9.55302 9.10138 9.10294C9.55147 8.65285 10.1619 8.39999 10.7984 8.39999H17.9984C18.635 8.39999 19.2454 8.65285 19.6955 9.10294C20.1456 9.55302 20.3984 10.1635 20.3984 10.8V18C20.3984 18.6365 20.1456 19.247 19.6955 19.6971C19.2454 20.1471 18.635 20.4 17.9984 20.4H10.7984C10.1619 20.4 9.55147 20.1471 9.10138 19.6971C8.65129 19.247 8.39844 18.6365 8.39844 18V10.8Z"
        fill="currentColor"
      />
      <path
        d="M6.00156 3.60001C5.36504 3.60001 4.75459 3.85286 4.30451 4.30295C3.85442 4.75304 3.60156 5.36349 3.60156 6.00001V13.2C3.60156 13.8365 3.85442 14.447 4.30451 14.8971C4.75459 15.3471 5.36504 15.6 6.00156 15.6V6.00001H15.6016C15.6016 5.36349 15.3487 4.75304 14.8986 4.30295C14.4485 3.85286 13.8381 3.60001 13.2016 3.60001H6.00156Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
