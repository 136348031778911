import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Link as MUILink,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import {
  Card,
  CheckGrey,
  Description,
  Dollar,
  Folder,
  Patient,
  Percent,
  Person,
  Provider2,
  Thermometer,
  X,
} from "components/oldDesignAssets/icons";
import { endOfDay, format } from "date-fns";
import { cloneDeep } from "lodash";
import { Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useUsmsUser from "../../../hooks/useUsmsUser";
import { createAvixoUser, getRosterStatusProvider } from "../../../services/dispatcherService";
import {
  assignRequest,
  editBusiness,
  editClinicalNotes,
  editPayment,
  editSchedule,
} from "../../../services/requestService";
import { requestStore } from "../../../stores/modalManager";
import { store } from "../../../stores/storeManager";
import { getAvailablePaymentMethods } from "../../../utils/getAvailablePaymentMethods";
import { getFormattedAddress, getPaymentIcon } from "../../../utils/globalUtils";
import notify from "../../../utils/notify";
import Price from "../../global/price";
import Avatar from "../avatar";
import Button from "../button";
import Footer from "../footer";
import Header from "../header";
import { ArrowLeft, Building, Heartbeat, PatientNotes } from "../icons";
import Input from "../input";
import InputButton from "../inputButton";
import Schedule from "../schedule";
import StatusTag from "../statusTag";
import Tag from "../tag";
import Timer from "../timer";
import Tooltip from "../tooltip";
import AddressList from "./addressList";
import Cancellation from "./cancellation";
import ClinicList from "./clinicList";
import CreateUser from "./createUser";
import EditDeliveryRequest from "./editDeliveryRequest";
import PaymentMethod from "./paymentMethod";
import ProviderAssignment from "./providerAssignment";
import Request from "./request";

export default function EditRequest() {
  const { request, hideModal } = requestStore.useState(s => ({
    request: { ...s.request, ...s.unsavedChanges },
    hideModal: s.hideModal,
  }));
  const { currencyToUse, language, country } = store.useState(s => ({
    currencyToUse: s.country.currencySymbol,
    language: s.language,
    country: s.country,
  }));

  const [isLoading, setIsLoading] = useState(false);

  const { t = () => "" } = useTranslation();
  const serviceStatus = t(`${language}.serviceStatus`);

  const payer = useUsmsUser(request.owner || request.user, country.alpha2);
  const patient = useUsmsUser(request.user, country.alpha2);
  const paymentMethod = request.availablePaymentMethods.find(pm => pm._id === request.billing.preferredMethod);
  const { free, totals, code } = request.billing;
  const symptomRecord = request.records.find(r => r.type === "symptoms") || {};
  const clinicalNote = request.records.find(r => r.type === "clinicalNotes")?.data.clinicalNotes[0].message;
  const addressDetails = request.schedule.requestedAddress;
  const programTitle = request.case.program?.title || "";
  const caseBusinessTag = request.case.business?.tag || "";
  const caseId = (request.case.id && `C-${request.case.id}`) || "(New)";

  const checkRosterStatusProviders = async (start, end) => {
    if (!(Array.isArray(request.providers) && request.providers.length)) return;

    const updatedProviders = [];

    for (const provider of request.providers) {
      const updateProvider = { ...provider };
      try {
        const rosterStatus = await getRosterStatusProvider(provider.user._id, {
          start: start || request.schedule.start || new Date(),
          end: end || request.schedule.end || endOfDay(new Date()),
        });

        updateProvider.adhoc = Boolean(rosterStatus.data?.isAdHoc);
      } catch (err) {
        notify(err.data, "error");
      }

      updatedProviders.push(updateProvider);
    }

    updateRequest({
      providers: updatedProviders,
    });
  };

  const onChangeSchedule = updatedFields => {
    updateRequest({
      schedule: { ...request.schedule, ...updatedFields },
      isDirty: { ...request.isDirty, schedule: true },
    });

    checkRosterStatusProviders(updatedFields.start, updatedFields.end);
  };

  const updateRequest = updatedFields => {
    requestStore.update(s => {
      for (const field in updatedFields) {
        s.unsavedChanges[field] = updatedFields[field];
      }
    });
  };

  const showSubscreen = useCallback(subscreen => {
    requestStore.update(s => {
      s.componentsToRender = subscreen;
    });
  }, []);

  const onClickSave = async () => {
    try {
      setIsLoading(true);
      let updateResult;
      const messages = [];

      if (request.isDirty.schedule && ["accepted", "assigned", "visitEnd", "completed"].includes(request.status)) {
        updateResult = await editSchedule(request._id, {
          scheduleStart: request.schedule.start,
          scheduleEnd: request.schedule.end,
        });
        messages.push(updateResult.data.message);
      }

      if (request.isDirty.address && ["accepted", "assigned", "visitEnd", "completed"].includes(request.status)) {
        updateResult = await editSchedule(request._id, {
          requestedAddress: request.schedule.requestedAddress,
        });
        messages.push("Address updated");
      }

      if (request.isDirty.providers && ["accepted", "assigned"].includes(request.status)) {
        updateResult = await assignRequest(request._id, {
          providers: request.providers.map(p => ({ userRef: p.user._id, owner: p.owner, adhoc: p.adhoc })),
          scheduleStart: request.schedule.start,
          scheduleEnd: request.schedule.end,
          requestedAddress: request.schedule.requestedAddress,
        });
        messages.push(updateResult.data.message);
      }

      if (
        request.isDirty.business &&
        ["accepted", "assigned", "inTransit", "inProgress", "visitEnd"].includes(request.status)
      ) {
        updateResult = await editBusiness(request._id, {
          businessRef: request.business?._id,
        });
        messages.push(updateResult.data.message);
      }

      if (request.isDirty.billing) {
        updateResult = await editPayment(request._id, {
          free: request.billing.free,
          paymentMethodRef: request.billing.preferredMethod,
          businessRef: paymentMethod?.businessRef,
          billingNote: request.billing.note,
          codeId: request.billing.code?.id || "",
        });
        messages.push(updateResult.data.message);
      }

      if (request.isDirty.clinicalNotes) {
        updateResult = await editClinicalNotes(request._id, { clinicalNote });
        messages.push(updateResult.data.message);
      }

      if (updateResult) {
        const service = updateResult.data.service;
        requestStore.update(s => {
          s.request = service;
          s.unsavedChanges = {
            billing: service.billing,
            schedule: service.schedule,
            providers: service.providers,
            isDirty: {},
          };
        });
        if (messages.length) notify(messages.join(", "));
        showRequestScreen();
      }
    } catch (error) {
      notify(error.data, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const providerList = request.providers.map((p, i) => (
    <div className="providerInfoItem" key={i}>
      <Avatar userData={p.user} />
      <div className="providerDetail">
        <div className="name">
          <div className="fullName">{p.user.name}</div>
          {p.owner ? <div className="primaryInfo">Primary</div> : ""}
        </div>
        <div className="type">{p.user.specialty}</div>
      </div>
      <div className="seenStatus">
        {p.seen?.status ? (
          <CheckRoundedIcon sx={{ fontSize: "16px", stroke: "#144FE7", strokeWidth: 2 }} />
        ) : (
          <CheckGrey />
        )}
      </div>
    </div>
  ));

  const showRequestScreen = () => {
    requestStore.update(s => {
      s.unsavedChanges = {
        isDirty: {},
      };
    });
    showSubscreen(<Request />);
  };

  const showProviderScreen = useCallback(() => showSubscreen(<ProviderAssignment origin="edit" />), [showSubscreen]);

  const userBilling = () => {
    const paymentSymbol = () => {
      if (free) return null;
      else if (paymentMethod) return paymentMethod.type === "card" ? <Card /> : <Dollar />;
      else return <Tooltip />;
    };

    const captionText = () => {
      if (free) return "Patient will not be charged";
      else if (paymentMethod) return paymentMethod.name || paymentMethod.data.name;
      else return "Payment method missing or invalid";
    };

    return (
      <div className="userBilling">
        {paymentSymbol()}
        <span className={`accountNumber ${paymentMethod && !free ? "" : "invalid"} ${free ? "free" : ""}`}>
          {captionText()}
        </span>
      </div>
    );
  };

  const onChangeClinicalNote = message => {
    requestStore.update(s => {
      const newRecords = cloneDeep(request.records);
      let record = newRecords.find(r => r.type === "clinicalNotes");
      if (!record) {
        record = { type: "clinicalNotes", data: {} };
        newRecords.push(record);
      }
      record.data.clinicalNotes = [{ message }];
      s.unsavedChanges.records = newRecords;
      s.unsavedChanges.isDirty.clinicalNotes = true;
    });
  };

  const PaymentMethodText = () => {
    if (free) return "Free of Charge";
    else if (paymentMethod?.origin === "business") {
      return (
        <div className="corporatePaymentMethod">
          <Tag>{paymentMethod.name}</Tag> {paymentMethod.data.name}
        </div>
      );
    } else if (request.billing.note) {
      return (
        <>
          {paymentMethod.name}
          <span>{request.billing.note}</span>
        </>
      );
    } else if (paymentMethod) return paymentMethod.name || paymentMethod.data?.name;
    else return "Select payment method";
  };

  const isDirty = () => {
    for (const field in request.isDirty) {
      if (request.isDirty[field]) return true;
    }
    return false;
  };

  const onSuccessEditUser = useCallback(
    updatedUser => {
      requestStore.update(s => {
        if (s.request.owner?._id === updatedUser._id) {
          s.request.owner = updatedUser;
          s.request.availablePaymentMethods = getAvailablePaymentMethods({
            country,
            owner: updatedUser,
            user: request.user,
          });
        } else {
          s.request.user = updatedUser;
          s.request.availablePaymentMethods = getAvailablePaymentMethods({
            country,
            user: updatedUser,
            owner: request.owner,
          });
        }
      });
    },
    [country, request?.owner, request?.user],
  );

  const onClickEditPayer = useCallback(
    () =>
      showSubscreen(
        <CreateUser
          title="User Details"
          textAction="Update User"
          onSuccess={onSuccessEditUser}
          userData={payer}
          isOwner
        />,
      ),
    [onSuccessEditUser, payer, showSubscreen],
  );

  const onClickEditPatient = useCallback(
    () =>
      showSubscreen(
        <CreateUser
          title="Patient Details"
          textAction="Update Patient"
          onSuccess={onSuccessEditUser}
          userData={patient}
        />,
      ),
    [onSuccessEditUser, patient, showSubscreen],
  );

  const onClickCreateAvixoUser =
    ({ jarvisRef }) =>
    async e => {
      e.preventDefault();

      try {
        const { data } = await createAvixoUser(jarvisRef, country.alpha2);
        if (request.owner?._id === jarvisRef) updateRequest({ owner: data.user });
        if (request.user?._id === jarvisRef) updateRequest({ user: data.user });
      } catch (error) {
        console.log(error);
        notify(error.data, "error");
      }
    };

  const onChangeBusiness = e => {
    const selectedBusiness = patient.businesses.find(b => b.ref?.id === e.target.value)?.ref;

    requestStore.update(s => {
      s.unsavedChanges.business = selectedBusiness;
      s.unsavedChanges.isDirty.business = true;
    });
  };

  if (request.type === "Delivery" && request.specialty === "Rider") return <EditDeliveryRequest />;

  return (
    <div className="modalContent requestUM">
      <Header>
        <div className="title edit">
          <span className="back">
            <ArrowLeft onClick={showRequestScreen} />
          </span>
          Edit Request
          <span className="subtitle">{`S${request.id}`}</span>
          <Typography variant="caption" color="purple.500" ml={1} mt={0.25}>
            {request.origin}
          </Typography>
        </div>
        <StatusTag className={`${request.status}`}>{serviceStatus[request.status]}</StatusTag>
        <X className="close" onClick={hideModal} />
      </Header>

      <div className="content">
        <div className="scheduleSection">
          <InputButton
            className="editAddress"
            ariaLabel="edit-address"
            text={
              <div className="editAddressText">
                <div className="addressUM">{getFormattedAddress(addressDetails)}</div>
              </div>
            }
            onClick={() => showSubscreen(request.type === "Clinic" ? <ClinicList /> : <AddressList />)}
            disabled={request.status === "completed"}
          />
        </div>

        <div className="provider">
          <div className="providerGroup edit">
            <Provider2 />
            {request.status === "accepted" && !request.providers.length ? (
              <>
                <Button text="Assign provider" type="button" className="primary" onClick={showProviderScreen} />
                {request.type === "Video" && !request.schedule.start && <Timer startTime={request.queuedAt} />}
              </>
            ) : ["accepted", "assigned"].includes(request.status) ? (
              <InputButton
                className="editProvider"
                ariaLabel="edit-provider"
                text={providerList}
                onClick={showProviderScreen}
              />
            ) : (
              <div className="providerInfo">{providerList}</div>
            )}
          </div>
        </div>

        {["accepted", "assigned", "inTransit", "inProgress", "visitEnd", "completed"].includes(request.status) ? (
          <>
            <div className="scheduleStart">
              <Schedule onChange={onChangeSchedule} disabled={["inTransit", "inProgress"].includes(request.status)} />
            </div>

            <div className="userSection">
              <Input
                disabled
                icon={<Person />}
                value={payer.name}
                className="userEdit"
                editInfo={
                  request.status !== "completed" && (
                    <div className="inputEdit">
                      {payer.platos?.length ? (
                        <a
                          href={`${
                            process.env.REACT_APP_AVIXO_URL
                          }${country.alpha2.toLowerCase()}/patient/default/view?id=${payer.platos[0].id}`}
                          target="_blank"
                          rel="noreferrer">
                          Open in Avixo
                        </a>
                      ) : (
                        <a href="/" onClick={onClickCreateAvixoUser({ jarvisRef: payer._id })}>
                          Create in Avixo
                        </a>
                      )}
                      <Link to="#" aria-label="edit-payer" onClick={onClickEditPayer}>
                        Edit
                      </Link>
                    </div>
                  )
                }
              />
            </div>

            <InputButton
              className="userBilling edit"
              icon={getPaymentIcon(paymentMethod, request)}
              text={<PaymentMethodText />}
              onClick={() => showSubscreen(<PaymentMethod />)}
              disabled={request.status === "completed"}
            />
            <Grid container px={2} gap={2}>
              <Box display="flex" height={30} alignItems="center">
                <Building />
              </Box>

              <Grid item xs alignSelf="center">
                <FormControl fullWidth>
                  <Select
                    value={request.business?._id || ""}
                    onChange={onChangeBusiness}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                      elevation: 2,
                      sx: {
                        ".MuiMenuItem-root.Mui-selected": {
                          backgroundColor: "transparent",
                        },
                      },
                      PaperProps: {
                        sx: { mt: 0.5, maxHeight: 280 },
                      },
                    }}
                    input={
                      <OutlinedInput
                        size="small"
                        color="secondary"
                        sx={{
                          height: "30px",
                          minHeight: "unset",
                          fontSize: "12px",
                          ".MuiOutlinedInput-input": { paddingLeft: "10px" },
                        }}
                        fullWidth
                      />
                    }
                    disabled={
                      !patient.businesses?.length ||
                      !["accepted", "assigned", "inTransit", "inProgress", "visitEnd"].includes(request.status)
                    }
                    displayEmpty>
                    <MenuItem key="" value="" sx={{ fontSize: "12px" }}>
                      <em>- No Corporate -</em>
                    </MenuItem>

                    {patient.businesses?.map(b => (
                      <MenuItem key={b.ref?._id} value={b.ref?._id} sx={{ fontSize: "12px" }}>
                        {b.name} [{b.ref?.tag}]
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText sx={{ ml: "10px" }}>
                    Select if appointment is to be tagged to a corporate
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <div className="userSection">
              <Input
                disabled
                icon={<Patient />}
                value={patient.name}
                className="userEdit"
                editInfo={
                  request.status !== "completed" && (
                    <div className="inputEdit">
                      {patient.platos?.length ? (
                        <a
                          href={`${
                            process.env.REACT_APP_AVIXO_URL
                          }${country.alpha2.toLowerCase()}/patient/default/view?id=${patient.platos[0].id}`}
                          target="_blank"
                          rel="noreferrer">
                          Open in Avixo
                        </a>
                      ) : (
                        <a href="/" onClick={onClickCreateAvixoUser({ jarvisRef: patient._id })}>
                          Create in Avixo
                        </a>
                      )}
                      <Link to="#" aria-label="edit-patient" onClick={onClickEditPatient}>
                        Edit
                      </Link>
                    </div>
                  )
                }
              />
            </div>
          </>
        ) : (
          <div className="userSection">
            <div className="user">
              <Person />
              <div className="userDetails">
                <span>{payer.name}</span>
                {payer.phoneNumbers?.length && payer.phoneNumbers[0] ? (
                  <a href={`tel:${payer.phoneNumbers[0].ext}${payer.phoneNumbers[0].number}`}>
                    {payer.phoneNumbers[0].ext} {payer.phoneNumbers[0].number}
                  </a>
                ) : (
                  <span>N/A</span>
                )}
                {payer.email.address ? (
                  <span className="light" href={`mailto:${payer.email.address}`}>
                    {payer.email.address}
                  </span>
                ) : (
                  <span>N/A</span>
                )}
              </div>
            </div>
            {userBilling()}
          </div>
        )}

        <div className="caseType">
          <InputButton
            icon={<Folder />}
            disabled
            text={
              request.case.type ? (
                <>
                  {request.case.type} {programTitle} {request.case.package?.name} {caseBusinessTag} {caseId}
                </>
              ) : (
                ""
              )
            }
          />
        </div>

        <hr className="addressSeparator" />

        <div className={"patientSection edit"}>
          <div className="patient">
            <Patient />
            {patient.name}
          </div>
        </div>

        <div className={"symptomsUM edit"}>
          <Thermometer />
          {(symptomRecord.data?.symptoms && symptomRecord.data.symptoms.join(", ")) || "N/A"}
        </div>

        {request.type === "Delivery" && (
          <div className={"notes edit"}>
            <Heartbeat />
            <div>
              <span className="preWrap">{symptomRecord.data?.patientCondition || "N/A"}</span>
            </div>
          </div>
        )}

        <div className={"notes edit"}>
          <PatientNotes />
          <div>
            <span className="preWrap">{symptomRecord.data?.notes || "N/A"}</span>
          </div>
        </div>

        <Input
          className="clinicalNotes edit"
          icon={<Description />}
          noBorder
          value={clinicalNote}
          placeholder="Add clinical notes"
          isMultiLine
          counter
          maxLength={4000}
          onChange={onChangeClinicalNote}
          disabled={request.status === "completed"}
        />

        {code?.id && (
          <Grid className="notes edit" sx={{ svg: { path: { fill: "#979797" } } }}>
            <Percent />
            <Box>Discount: {code.id}</Box>
          </Grid>
        )}

        {request.items.length > 0 && (
          <div className="feesTable edit">
            <table>
              <thead>
                <tr>
                  <th>Service Item</th>
                  <th>Qty</th>
                  <th>Base {currencyToUse}</th>
                  <th>Disc</th>
                  <th>Price {currencyToUse}</th>
                </tr>
              </thead>

              <tbody>
                {request.items.map((item, i) => (
                  <Fragment key={i}>
                    <tr>
                      <td>
                        {item.description}
                        {!!item.price?.discount?.type && (
                          <Typography component="p" variant="caption" color="successGreen.500">
                            Discount {item.price.discount.value.toLocaleString()}
                            {item.price.discount.type === "percentage" && "%"}
                          </Typography>
                        )}
                      </td>
                      <td>x{item.quantity}</td>
                      <td>{isNaN(item.price?.base) ? "N/A" : Number(item.price.base).toLocaleString()}</td>
                      <td>{item.price?.discountedAmount ? item.price?.discountedAmount?.toLocaleString() : "-"}</td>
                      <td>{isNaN(item.price?.total) ? "N/A" : Number(item.price.total).toLocaleString()}</td>
                    </tr>
                  </Fragment>
                ))}

                {request.billing.code?.itemTags?.length === 0 && !request.plato?.invoiceId && (
                  <tr>
                    <td className="discountItem">
                      {request.billing.code.type === "percentage" ? (
                        `${Number(request.billing.code.value).toLocaleString()}% Grand Total Discount`
                      ) : (
                        <Price value={request.billing.code.value} postfix=" Fixed Discount" />
                      )}
                    </td>
                    <td>x1</td>
                    <td>-</td>
                    <td>{`${Number(request.billing.code.value).toLocaleString()}${
                      request.billing.code.type === "percentage" ? "%" : ""
                    }`}</td>
                    <td>
                      -
                      {Number(
                        request.billing.code.type === "percentage"
                          ? (request.billing.code.value / (100 - request.billing.code.value)) * totals.grand
                          : request.billing.code.value,
                      ).toLocaleString()}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <Divider />

            {request.billing.totals.amountDue !== undefined && (
              <Grid container justifyContent="space-between" mt={1.5}>
                <Typography component="span" variant="caption" fontWeight={600}>
                  Subtotal
                </Typography>

                <Typography component="span" variant="caption" fontWeight={600}>
                  {Number(totals.subtotal).toLocaleString()}
                </Typography>
              </Grid>
            )}

            {!!totals.taxes && (
              <Grid container justifyContent="space-between" mt={0.25}>
                <Typography component="span" variant="caption" color="text.secondary">
                  GST
                </Typography>

                <Typography component="span" variant="caption" color="text.secondary">
                  {Number(totals.taxes || 0).toLocaleString()}
                </Typography>
              </Grid>
            )}

            {!!totals.discount && (
              <Grid container justifyContent="space-between" mt={0.25}>
                <Typography component="span" variant="caption" color="successGreen.500">
                  Discount
                </Typography>

                <Typography component="span" variant="caption" color="successGreen.500">
                  {Number(totals.discount).toLocaleString()}
                </Typography>
              </Grid>
            )}

            {request.billing.totals.amountDue !== undefined && <Divider sx={{ mt: 1.5 }} />}

            <Grid container justifyContent="space-between" mt={1.5}>
              <Typography component="span" variant="caption" fontWeight={600}>
                Total
              </Typography>

              <Typography component="span" variant="caption" fontWeight={600}>
                {Number(totals.grand).toLocaleString()}
              </Typography>
            </Grid>

            {totals?.paid > 0 && (
              <Grid container justifyContent="space-between" mt={0.25}>
                <Typography component="span" variant="caption" color="text.secondary">
                  Amount paid
                </Typography>

                <Typography component="span" variant="caption" color="text.secondary">
                  {Number(totals.paid).toLocaleString()}
                </Typography>
              </Grid>
            )}

            {paymentMethod?.origin === "card" && (
              <Grid container mt={0.25}>
                <MUILink
                  href={`${process.env.REACT_APP_STRIPE_PAYMENT_URI}/${paymentMethod.paymentIntentId}`}
                  target="_blank"
                  color="wildTide.500"
                  fontWeight={600}>
                  {paymentMethod.name} - Charged
                  {paymentMethod.addedAt ? format(new Date(paymentMethod.addedAt), " 'on' dd MMM yyyy, HH.mm") : ""}
                </MUILink>
              </Grid>
            )}

            {Number(totals.amountDue) < 0 && (
              <Grid container justifyContent="space-between" mt={0.25}>
                <Typography component="span" variant="caption" color="warning.main">
                  Amount overpaid
                </Typography>

                <Typography component="span" variant="caption" color="warning.main">
                  {Math.abs(Number(totals.amountDue)).toLocaleString()}
                </Typography>
              </Grid>
            )}
            <Grid height="16px" />
          </div>
        )}
      </div>

      <Footer>
        <hr />
        <Grid p={2}>
          <div className="actions">
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs container wrap="nowrap">
                {["accepted", "assigned", "inTransit", "inProgress", "visitEnd", "completed"].includes(
                  request.status,
                ) && (
                  <Link to="#" type="button" className="linkRed" onClick={() => showSubscreen(<Cancellation />)}>
                    Cancel
                  </Link>
                )}
              </Grid>
              <Grid
                item
                xs={8}
                container
                className="rightActions"
                alignItems="center"
                wrap="nowrap"
                justifyContent="flex-end">
                {request.status !== "cancelled" && (
                  <Button
                    text="Save Changes"
                    type="button"
                    className="primary"
                    disabled={isLoading || !isDirty()}
                    onClick={onClickSave}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Footer>
    </div>
  );
}
